import React from 'react';
import './Accounts.css';
import { gql, useQuery } from '@apollo/client';
import { Card, Divider } from 'antd';

export const GET_ACCOUNTS = gql`
    query GetAccount($accountId: Float!) {
        account(id: $accountId) {
            id
            name
            accountNumber
            providerName
            serviceCategoryName
        }
    }
`;

interface AccountData {
    account: {
        id: number;
        name: string;
        accountNumber: string;
        providerName: string;
        serviceCategoryName: string;
    }
}

interface AccountVars {
    accountId: number;
}

const Account: React.FC<{ accountId: number }> = ({ accountId }) => {
    const { loading, error, data } = useQuery<AccountData, AccountVars>(
        GET_ACCOUNTS,
        {
            variables: {
                accountId
            }
        }
    );

    return (
        <>
            <h1>{data?.account?.name}</h1>
            <h4>Account Information</h4>

            <Card style={{ background: '#ffffff' }} title="Card title" bordered={false}>
                {data?.account.providerName}
                <Divider />
                {data?.account.serviceCategoryName}
                <br />
                {data?.account.accountNumber}
                <br />
            </Card>


        </>
    );
};

export default Account;
