import React, { useEffect, useState } from 'react';
import { Radio, Button, Spin, message, Space, Input } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { decodeToken } from "react-jwt";
import './Mfa.css';

// GraphQL queries and mutations
export const GET_USER_MFA_DATA = gql`
  query GetUserMfaData($userId: Float!) {
    getUserMfaData(id: $userId) {
      email
      phoneNumbersList {
        id
        number
        type
      }
    }
  }
`;

const SEND_CONFIRMATION_TOKEN = gql`
  mutation SendConfirmationToken($username: String!, $type: String!) {
    sendConfirmationToken(username: $username, type: $type) {
      username
      tokenSent
    }
  }
`;

const VERIFY_CONFIRMATION_TOKEN = gql`
  mutation VerifyConfirmationToken($username: String!, $token: String!) {
    verifyConfirmationToken(username: $username, token: $token) {
      accessToken
      refreshToken
    }
  }
`;

const Mfa: React.FC = () => {
  const [selectedMethod, setSelectedMethod] = useState('');
  const [tokenSent, setTokenSent] = useState(false);
  const [mfaCode, setMfaCode] = useState('');

  // Decode the JWT token to get userId and username
  const token = localStorage.getItem('accessToken');
  const result = decodeToken(token as string);
  const { id, username } = result as any;

  const { data, loading, error } = useQuery(GET_USER_MFA_DATA, {
    variables: { userId: id },
  });

  const [sendConfirmationToken, { loading: tokenLoading }] = useMutation(SEND_CONFIRMATION_TOKEN);
  const [verifyConfirmationToken, { loading: verifyLoading }] = useMutation(VERIFY_CONFIRMATION_TOKEN);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      message.error('Failed to load MFA data');
    }
  }, [error]);

  const handleSendToken = async () => {
    const [methodType, methodValue] = selectedMethod.split('-');
    try {
      const response = await sendConfirmationToken({
        variables: {
          username,
          type: methodType,
        },
      });
      if (response.data.sendConfirmationToken.tokenSent) {
        setTokenSent(true);
        message.success('MFA token sent successfully');
      } else {
        message.error('Failed to send MFA token');
      }
    } catch (e) {
      message.error('Failed to send MFA token');
    }
  };

  const handleContinue = () => {
    if (!selectedMethod) {
      message.error('Please select an MFA method');
      return;
    }
    handleSendToken();
  };

  const handleVerify = async () => {
    try {
      const response = await verifyConfirmationToken({
        variables: {
          username,
          token: mfaCode,
        },
      });
      const { accessToken, refreshToken } = response.data.verifyConfirmationToken;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      message.success('MFA verified successfully');
      localStorage.setItem('userId', id);
      navigate('/bills');
    } catch (e) {
      message.error('Failed to verify MFA code');
    }
  };

  if (loading || tokenLoading || verifyLoading) {
    return (
      <div className="mfa-container">
        <Spin size="large" />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div className="mfa-container">
      <div className="mfa-content">
        {!tokenSent ? (
          <>
            <h2 style={{ margin: 0 }}>Verify Your Identity</h2>
            <p>Select the method through which you'd like to verify your account</p>
            <Radio.Group onChange={(e) => setSelectedMethod(e.target.value)}>
              <Space direction="vertical">
                <Radio value={`email-${data.getUserMfaData.email}`}>
                  {data.getUserMfaData.email}
                </Radio>
                {data.getUserMfaData.phoneNumbersList.map((phone: any) => (
                  <Radio key={phone.id} value={`phone-${phone.number}`}>
                    {phone.number} ({phone.type})
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
            <Button type="primary" onClick={handleContinue} disabled={!selectedMethod}>
              Continue
            </Button>
          </>
        ) : (
          <>
            <h2 style={{ margin: 0 }}>MFA Code</h2>
            <p>Enter your MFA Code. The code is valid for 5 minutes</p>
            <Input
              placeholder="Enter MFA code"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
            />
            <Button type="primary" onClick={handleVerify} disabled={!mfaCode}>
              Verify
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Mfa;
