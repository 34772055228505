import React, { useState } from "react";
import { Button, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios"; // Import axios for REST API calls
import type { UploadFile } from "antd/es/upload/interface";
import { getApiHost } from '../../apolloClient';

const { Dragger } = Upload;
const apiUrl = getApiHost();

const Uploads: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleUpload = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      
      // Ensure you're appending the actual file objects
      fileList.forEach(file => {
        if (file.originFileObj) {
          formData.append("files", file.originFileObj); // Use originFileObj to get the real file
        }
      });

      try {
        const response = await axios.post(
          `${apiUrl}/users/upload-unprocessed-docs`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("accessToken"), // Ensure the token is included
            },
          }
        );
        message.success(`Files uploaded successfully.`);
        setFileList([]); // Clear the file list after successful upload
      } catch (error) {
        console.error("Upload error:", error);
        message.error(`File upload failed.`);
      }
    } else {
      message.warning("No files selected for upload.");
    }
  };

  const handleChange = (info: any) => {
    // Update fileList with the real files
    const newFileList = info.fileList.map((file: any) => ({
      ...file,
      status: "done",
    }));

    setFileList(newFileList);
  };

  return (
    <div style={{ padding: 20, marginTop: 20 }}>
      <Dragger
        accept="application/pdf"
        listType="picture-card"
        multiple={true}
        beforeUpload={() => false} // Prevent auto upload
        onChange={handleChange}
        fileList={fileList}
        showUploadList={{ showRemoveIcon: true }} // Show remove option for each file
      >
        <div>
          <InboxOutlined style={{ fontSize: "48px" }} />
          <div style={{ marginTop: 8 }}>Click or drag to upload PDFs</div>
        </div>
      </Dragger>
      <Button
        type="primary"
        onClick={handleUpload}
        style={{ marginTop: 20 }}
        disabled={fileList.length === 0} // Disable button if no files selected
      >
        Upload Selected Files
      </Button>
    </div>
  );
};

export default Uploads;
