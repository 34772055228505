import React, { useState, useEffect } from 'react';
import { List, Input, Spin, Card, Button, Row, Col, DatePicker, Select, Typography, InputNumber, Flex } from 'antd';
import type { DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
import './Accounts.css';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import AccountDetailsModal from './components/AccountDetailsModal';
import ConfirmPaymentsModal from './components/ConfirmPaymentsModal';
import useUserAccountsExternalBankAccounts, { Account } from '../../hooks/useUserAccountsExternalBankAccounts';
import Link from 'antd/es/typography/Link';
import AddAccountModal from './components/AddAccountModal';
const { Text } = Typography;

interface AccountData {
  paymentAmount: number;
  sendOnDate: Dayjs | null;
  payFromAccount: string;
}

const Accounts: React.FC = () => {
  const { loading, error, data } = useUserAccountsExternalBankAccounts({ userId: Number(localStorage.getItem('userId')) });
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmPaymentsModalVisible, setConfirmPaymentsModalVisible] = useState(false);
  const [addAccountModalVisible, setAddAccountModalVisible] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);

  const [accountData, setAccountData] = useState<{ [key: string]: AccountData }>({});

  const onPayClick = () => {
    setConfirmPaymentsModalVisible(true);
  };

  const onAddAccountClick = () => {
    setAddAccountModalVisible(true);
  };

  const onSendOnChange = (accountId: string): DatePickerProps['onChange'] => (date, dateString) => {
    setAccountData(prevData => ({
      ...prevData,
      [accountId]: {
        ...prevData[accountId],
        sendOnDate: date as Dayjs,
      }
    }));
  };

  const onPayFromChange = (accountId: string) => (value: string) => {
    setAccountData(prevData => ({
      ...prevData,
      [accountId]: {
        ...prevData[accountId],
        payFromAccount: value,
      }
    }));
  };

  useEffect(() => {
    if (data) {
      const sortedAccounts = [...data.user.accounts].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setFilteredAccounts(sortedAccounts);
    }
  }, [data]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (data) {
      const filtered = data.user.accounts
        .filter(account => account.name.toLowerCase().includes(value.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));
      setFilteredAccounts(filtered);
    }
  };

  const maskAccountNumber = (accountNumber: string) => {
    return `...${accountNumber.slice(-4)}`;
  };

  const externalBankAccountOptions = data?.user?.externalBankAccounts.map((e) => {
    return {
      value: e.id,
      label: e.accountNickName + maskAccountNumber(e.accountNumber)
    };
  });

  const showModal = (accountId: number) => {
    setSelectedAccountId(accountId);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedAccountId(null);
  };

  const handleConfirmPaymentsModalClose = () => {
    setConfirmPaymentsModalVisible(false);
  };

  const handleAddAccountModalClose = () => {
    setAddAccountModalVisible(false);
  };

  const handlePaymentAmountChange = (id: string, value: number | string | undefined) => {
    const amount = Number(value) || 0;
    setAccountData(prevData => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        paymentAmount: amount,
      }
    }));
  };

  const totalScheduled = Object.values(accountData).reduce((total, data) => total + data.paymentAmount, 0);

  const accountsToPay = filteredAccounts.filter(account => {
    const accountInfo = accountData[account.id];
    return accountInfo && (accountInfo.paymentAmount > 0 || accountInfo.sendOnDate || accountInfo.payFromAccount);
  }).map(account => ({
    ...account,
    ...accountData[account.id]
  }));

  if (loading) return <Spin />;
  if (error) return <p>Error loading accounts.</p>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <h1>Accounts</h1>
      <Row justify="space-between">
        <span>
          <Input
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            style={{ marginBottom: 20, width: 200, marginRight: 10 }}
            prefix={<SearchOutlined />}
          />
          <Button type="primary" style={{ marginBottom: 0 }} onClick={onAddAccountClick}>
            + Add Account
          </Button>
        </span>
        <span>
          <Flex justify="flex-start" align='center'>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Total Scheduled: <Text keyboard>{new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(totalScheduled)}</Text>
            </Typography.Title>
            <Button type="primary" style={{ marginLeft: 10, marginBottom: 0 }} onClick={onPayClick} disabled={accountsToPay.length === 0}>
              Pay
            </Button>
          </Flex>
        </span>
      </Row>
      <div className="accounts-container">
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={filteredAccounts}
          renderItem={(account) => (
            <List.Item>
              <Card
                style={{ padding: 0, margin: 0 }}
              >
                <Row>
                  <Col xs={{ flex: '100%' }}
                    sm={{ flex: '50%' }}
                    md={{ flex: '40%' }}
                    lg={{ flex: '25%' }}
                    xl={{ flex: '25%' }}>
                    <Link onClick={() => showModal(Number(account.id))}>
                      {account.name + ' '}<EditOutlined />
                    </Link>

                    <br />
                    {maskAccountNumber(account.accountNumber)}
                  </Col>
                  <Col xs={{ flex: '100%' }}
                    sm={{ flex: '50%' }}
                    md={{ flex: '40%' }}
                    lg={{ flex: '25%' }}
                    xl={{ flex: '25%' }}
                    style={{ paddingRight: 20 }}>
                    Payment Amount <InputNumber
                      prefix='$'
                      onClick={(e) => e.stopPropagation()}
                      style={{ width: 100 }}
                      value={accountData[account.id]?.paymentAmount || ''}
                      onChange={(e) => handlePaymentAmountChange(account.id, e?.valueOf())}
                    /></Col>
                  <Col xs={{ flex: '100%' }}
                    sm={{ flex: '50%' }}
                    md={{ flex: '40%' }}
                    lg={{ flex: '25%' }}
                    xl={{ flex: '25%' }}
                    style={{ paddingRight: 20 }}>
                    Send On  <DatePicker
                      value={accountData[account.id]?.sendOnDate || null}
                      onChange={onSendOnChange(account.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Col>
                  <Col xs={{ flex: '100%' }}
                    sm={{ flex: '50%' }}
                    md={{ flex: '40%' }}
                    lg={{ flex: '25%' }}
                    xl={{ flex: '25%' }}>Pay From    <Select
                      style={{ width: 120 }}
                      value={accountData[account.id]?.payFromAccount || undefined}
                      onChange={onPayFromChange(account.id)}
                      onClick={(e) => e.stopPropagation()}
                      options={externalBankAccountOptions}
                    /></Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </div>
      <AccountDetailsModal
        accountId={selectedAccountId}
        visible={modalVisible}
        onClose={handleModalClose}
      />
      <ConfirmPaymentsModal
        accountsToPay={accountsToPay}
        visible={confirmPaymentsModalVisible}
        onClose={handleConfirmPaymentsModalClose}
      />
      <AddAccountModal
        visible={addAccountModalVisible}
        onClose={handleAddAccountModalClose}
      />
    </div >
  );
};

export default Accounts;
