import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { Table, Spin, Button, message, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';

const GET_UNPROCESSED_DOCS_QUERY = gql`
  query GetUnprocessedDocs($page: Int!, $limit: Int!, $search: String) {
    unprocessedDocs(page: $page, limit: $limit, search: $search) {
      items {
        id
        status
        filename
        isBill
        serviceAddress
        amountDue
        dueDate
        customerName
        vendor
      }
      count
    }
  }
`;

const RESET_UNPROCESSED_DOCS_STATUS_MUTATION = gql`
  mutation ResetUnprocessedDocStatus($ids: [Int!]!) {
    resetUnprocessedDocumentStatus(ids: $ids)
  }
`;

interface UnprocessedDoc {
  id: number;
  status: string;
  filename: string;
  isBill: boolean;
}

interface UnprocessedDocsData {
  unprocessedDocs: {
    items: UnprocessedDoc[];
    count: number;
  };
}

interface UnprocessedDocsVars {
  limit: number;
  page: number;
  search: string;
}

const UnprocessedDocsTable: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [unprocessedDocs, setUnprocessedDocs] = useState<UnprocessedDoc[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [fetchUnprocessedDocs] = useLazyQuery<
    UnprocessedDocsData,
    UnprocessedDocsVars
  >(GET_UNPROCESSED_DOCS_QUERY);
  const [resetDocStatus] = useMutation(RESET_UNPROCESSED_DOCS_STATUS_MUTATION);

  const columns: ColumnsType<UnprocessedDoc> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Is Bill",
      dataIndex: "isBill",
      key: "isBill",
      render: (isBill: boolean) => (isBill ? "Yes" : "No"),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Service Address",
      dataIndex: "serviceAddress",
      key: "serviceAddress",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "Amount Due",
      dataIndex: "amountDue",
      key: "amountDue",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleUserClick(record.id)}>
          Reset Status
        </Button>
      ),
    },
  ];

  const loadUnprocessedDocs = useCallback(() => {
    setLoading(true);
    fetchUnprocessedDocs({
      variables: { limit: 10, page, search: debouncedSearch },
      fetchPolicy: "network-only", // Ensure a fresh query every time
      onCompleted: (data) => {
        setUnprocessedDocs(data.unprocessedDocs.items);
        setTotal(data.unprocessedDocs.count);
        setLoading(false);
      },
      onError: () => {
        message.error("Error loading unprocessed documents");
        setLoading(false);
      },
    });
  }, [fetchUnprocessedDocs, page, debouncedSearch]);

  useEffect(() => {
    loadUnprocessedDocs();
  }, [loadUnprocessedDocs]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const cleanedSearch = search.replace(/\s+/g, ''); // Remove spaces
      if (cleanedSearch.length >= 3 || cleanedSearch.length === 0) {
        setDebouncedSearch(search);
      }
    }, 300); // 300ms delay
  
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const handleUserClick = async (unprocessedDocId: number) => {
    try {
      await resetDocStatus({ variables: { ids: [+unprocessedDocId] } });
      message.success(`Status reset for document ${unprocessedDocId}`);
      await loadUnprocessedDocs(); // Reload the data after mutation
    } catch (error) {
      message.error("Error resetting document status");
    }
  };

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleRefreshClick = () => {
    loadUnprocessedDocs(); // Manually trigger data refresh
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <h1 style={{ margin: 0 }}>Unprocessed Docs</h1>
        <Button type="default" onClick={handleRefreshClick}>
          Refresh
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "left",
          marginBottom: 2,
        }}
      >
        <Input
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
          style={{ width: 200, marginRight: 16 }}
        />
      </div>

      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={unprocessedDocs}
          pagination={{ current: page, pageSize: 10, total }}
          onChange={handleTableChange}
          rowKey="id"
        />
      </Spin>
    </>
  );
};

export default UnprocessedDocsTable;
