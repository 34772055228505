import React, { useEffect, useState } from "react";
import { Form, Button, Checkbox } from "antd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useLocation, Link } from "react-router-dom";
import "./TermsForm.css";

const GET_USER_DATA = gql`
  query getLimitedUserAccounts($guid: String!) {
    getLimitedUserAccounts(guid: $guid) {
      firstName
      lastName
      email
      bankAccounts {
        bankName
        accountNickname
      }
    }
  }
`;

const TERMS_ACKNOWLEDGEMENT_MUTATION = gql`
  mutation acknowledgeDwollaTermsAndConditions($guid: String!) {
    acknowledgeDwollaTermsAndConditions(guid: $guid)
  }
`;

const TermsForm: React.FC = () => {
  const [form] = Form.useForm();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false); // New state for controlling display
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const guid = queryParams.get("token");

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: { guid },
    skip: !guid,
  });

  const [acknowledgeTerms, { loading: mutationLoading }] =
    useMutation(TERMS_ACKNOWLEDGEMENT_MUTATION, {
      onCompleted: (response) => {
        if (response?.acknowledgeDwollaTermsAndConditions) {
          setAcknowledged(true); // Set acknowledged to true to show the message
        }
      },
      onError: () => {
        // Handle the error state if necessary
      },
    });

  const handleSubmit = async () => {
    await acknowledgeTerms({
      variables: { guid },
    });
  };

  useEffect(() => {
    setIsValid(data && checkboxChecked);
  }, [data, checkboxChecked]);

  const handleCheckboxChange = (e: any) => {
    setCheckboxChecked(e.target.checked);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching user data.</p>;

  if (acknowledged) {
    // Show the message once acknowledged
    return (
      <div className="terms-container">
        <h1 className="terms-title">Terms Acceptance Confirmation</h1>
        <div className="user-info">
          <p>
            Thank you for reviewing and agreeing to the Terms & Conditions
            documents. If you have any further questions or need assistance,
            please contact us at info@silverbills.com or 800-825-1924.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms Acknowledgement</h1>
      {data && (
        <div className="user-info">
          <h2 className="user-prop">
            Name:{" "}
            <span className="user-values">
              {data.getLimitedUserAccounts.firstName}{" "}
              {data.getLimitedUserAccounts.lastName}
            </span>
          </h2>
          <h2 className="user-prop">
            Email:{" "}
            <span className="user-values">
              {data.getLimitedUserAccounts.email}
            </span>
          </h2>
          <h3 className="bank-accounts-title">Bank Accounts</h3>
          <div className="terms-footer text-left">
            <p>
              Please review the following bank accounts that SilverBills has on
              file for use by your Account Manager to facilitate bill payments:
            </p>
          </div>
          <ul className="bank-accounts-list">
            {data.getLimitedUserAccounts.bankAccounts.map(
              (account: any, index: number) => (
                <li key={index} className="bank-account-item">
                  {account.bankName} - {account.accountNickname}
                </li>
              )
            )}
          </ul>
        </div>
      )}
      <Form
        form={form}
        onFinish={handleSubmit}
        name="terms"
        layout="vertical"
        className="terms-form"
      >
        <Form.Item>
          <div className="terms-footer text-left">
            <p>
              I agree that future payments to SilverBills will be processed by
              the Dwolla payment system from the accounts above. In order to
              cancel this authorization, I will contact my account manager and
              request cancellation.
            </p>
          </div>
          <Checkbox onChange={handleCheckboxChange}> </Checkbox>
          By continuing you agree to{" "}
          <Link to="https://silverbills.com/terms-of-use/" target="_blank">
            our Terms of Service
          </Link>{" "}
          and{" "}
          <Link to="https://silverbills.com/privacy-policy/" target="_blank">
            Privacy Policy
          </Link>
          , as well as{" "}
          <Link
            to="https://www.dwolla.com/legal/dwolla-account-terms-of-service"
            target="_blank"
          >
            Dwolla's Terms of Service
          </Link>{" "}
          and{" "}
          <Link to="https://www.dwolla.com/legal/privacy" target="_blank">
            Privacy Policy
          </Link>
          .
        </Form.Item>

        <Button
          htmlType="submit"
          type="primary"
          className="terms-form-button"
          disabled={!isValid || mutationLoading}
        >
          {mutationLoading ? "Please wait..." : "Agree and Continue"}
        </Button>
      </Form>
      <div className="terms-footer">
        <p>support@silverbills.com | 1-800-836-7562</p>
        <p>Version 1.5.2.202405101</p>
      </div>
    </div>
  );
};

export default TermsForm;
