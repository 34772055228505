import { ApolloClient, InMemoryCache, ApolloLink, concat, HttpLink } from '@apollo/client';

export const getApiHost = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('develop')) {
    return 'https://sbs-graphql-dev.silverbills.co';
  } else if (hostname.includes('stage')) {
    return 'https://sbs-graphql-stage.silverbills.co';
  } else if (hostname.includes('master')) {
    return 'https://sbs-graphql-master.silverbills.co';
  } else if (hostname.includes('localhost')) {
    return 'http://localhost:3000';
  } else if (hostname.includes('app.silverbills.com')) {
    return 'https://sbs-graphql.silverbills.com';
  } else {
    throw new Error(`Unrecognized hostname: ${hostname}`);
  }
};

const apiHost = getApiHost();
const getGraphQLUri = () => `${apiHost}/graphql`;

const httpLink = new HttpLink({
  uri: getGraphQLUri(), // Dynamically set the GraphQL endpoint
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // Add the authorization to the headers
  const accessToken = localStorage.getItem('accessToken');
  console.log(`operation.getContext().isUpload`, operation.getContext().isUpload);
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    }
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;
