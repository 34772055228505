import { useQuery, gql } from '@apollo/client';

export const GET_USER_ACCOUNTS_EXTERNAL_BANK_ACCOUNTS = gql`
  query GetUserAccounts($userId: Float!) {
    user(id: $userId) {
      accounts {
        id
        name
        accountNumber
      }
      externalBankAccounts {
        id
        accountNumber
        accountNickName
      }
    }
  }
`;

export interface Account {
  id: string;
  name: string;
  accountNumber: string;
}

export interface ExternalBankAccount {
  id: string;
  accountNumber: string;
  accountNickName: string;
}

export interface UserAccountsData {
  user: {
    accounts: Account[];
    externalBankAccounts: ExternalBankAccount[];
  };
}

export interface UserAccountsVars {
  userId: number;
}

export default function useUserAccountsExternalBankAccounts({ userId }: UserAccountsVars) {
  return useQuery<UserAccountsData, UserAccountsVars>(
    GET_USER_ACCOUNTS_EXTERNAL_BANK_ACCOUNTS,
    {
      variables: { userId },
    }
  );
}