import React, { useState } from 'react';
import { Upload, Button, message, List, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Title, Text } = Typography;

const BillUpload: React.FC = () => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);

  const handleUpload = async (options: any) => {
    const { file, onSuccess, onError } = options;
    setResponseData(null);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(
        `http://localhost:3000/users/bill-upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken')
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success(`${file.name} file uploaded successfully.`);
        setResponseData(response.data);
        onSuccess('ok');
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      message.error(`${file.name} file upload failed.`);
      onError(error);
    }
  };

  const handleChange = ({ file, fileList }: any) => {
    setFileList(fileList);
    if (file.status === 'uploading') {
      setPdfFile(URL.createObjectURL(file.originFileObj));
    }
  };

  return (
    <>
      <div className="scheduled-payments-container" style={{ padding: 20, marginTop: 20 }}>
        <Upload
          accept="application/pdf"
          customRequest={handleUpload}
          onChange={handleChange}
          fileList={fileList}
          showUploadList={true}
        >
          <Button icon={<UploadOutlined />}>Click to Upload PDF</Button>
        </Upload>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          {pdfFile && (
            <div style={{ flex: 1, marginRight: '20px' }}>
              <Title level={4}>PDF Preview</Title>
              <iframe
                src={pdfFile}
                title="PDF Preview"
                width="100%"
                height="600px"
                style={{ border: 'none' }}
              />
            </div>
          )}

          <div style={{ flex: 1 }}>
            {responseData && (<><Title level={4}>Extracted Bill Data</Title>
              <List
                bordered
                dataSource={Object.entries(responseData)}
                renderItem={([key, value]) => (
                  <List.Item>
                    <Text strong>{key.replace(/_/g, ' ')}:</Text> {String(value)}
                  </List.Item>
                )}
              /></>)}
          </div>

        </div>
      </div >
    </>
  );
};

export default BillUpload;
