import React, { useState } from 'react';
import { Modal, Button, List, Card, Row, Col, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const { Text } = Typography;

const CREATE_MULTIPLE_PAYMENTS = gql`
  mutation CreatePayments($payments: [CreatePaymentInput!]!) {
    createPayments(paymentsData: $payments) {
      successfulPayments {
        id
        externalBankAccountId
        amount
        payByDate
        estimatedDeliveryDate
        accountId
      }
      failedPayments {
        accountId
        amount
        externalBankAccountId
        payOnDate
        estimatedDeliveryDate
        failureReason
      }
    }
  }
`;

interface AccountDetailsModalProps {
  accountsToPay: any[];
  visible: boolean;
  onClose: () => void;
}

const maskAccountNumber = (accountNumber: string) => {
  return `...${accountNumber.slice(-4)}`;
};

const ConfirmPaymentsModal: React.FC<AccountDetailsModalProps> = ({ accountsToPay, visible, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [createPayments] = useMutation(CREATE_MULTIPLE_PAYMENTS);

  const handleSchedulePayments = async () => {
    setLoading(true);
    try {
      const payments = accountsToPay.map(account => ({
        amount: account.paymentAmount,
        accountId: account.id,
        externalBankAccountId: account.payFromAccount,
        payOnDate: account.sendOnDate,
      }));

      await createPayments({ variables: { payments } });

      message.success('Payments scheduled successfully.');

      onClose();
    } catch (error) {
      message.error(`Error scheduling payments: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const totalScheduled = accountsToPay.reduce((total, account) => total + (account.paymentAmount || 0), 0);

  return (
    <Modal
      title="Confirm Payments"
      visible={visible}
      onCancel={onClose}
      width="auto"
      footer={[
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '100%' }} key="footer-content">
          <Typography.Title level={5} style={{ margin: 0, marginRight: 10 }}>
            Total Scheduled: <Text keyboard>{new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(totalScheduled)}</Text>
          </Typography.Title>
          <Button type="primary" onClick={handleSchedulePayments} loading={loading} iconPosition="end">
            Schedule Payments
          </Button>
        </div>
      ]}
    >
      <p>Payments will begin processing on the “Send On” date selected. You may cancel or modify them up to 24 hours prior to the “Send On” date.</p>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={accountsToPay}
        renderItem={(account: any) => (
          <List.Item>
            <Card
              style={{ padding: 0, margin: 0 }}
            >
              <Row>
                <Col xs={{ flex: '100%' }}
                  sm={{ flex: '50%' }}
                  md={{ flex: '40%' }}
                  lg={{ flex: '25%' }}
                  xl={{ flex: '25%' }}>
                  <Text strong>{account.name}</Text>
                  <br />
                  <Text>{maskAccountNumber(account.accountNumber)}</Text>
                </Col>
                <Col xs={{ flex: '100%' }}
                  sm={{ flex: '50%' }}
                  md={{ flex: '40%' }}
                  lg={{ flex: '25%' }}
                  xl={{ flex: '25%' }}
                  style={{ paddingRight: 20 }}>
                  <Text>Payment Amount</Text>
                  <br />
                  <Text keyboard>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(account.paymentAmount)}</Text>
                </Col>
                <Col xs={{ flex: '100%' }}
                  sm={{ flex: '50%' }}
                  md={{ flex: '40%' }}
                  lg={{ flex: '25%' }}
                  xl={{ flex: '25%' }}
                  style={{ paddingRight: 20 }}>
                  <Text>Send On</Text>
                  <br />
                  <Text keyboard>{account.sendOnDate ? dayjs(account.sendOnDate).format('MM/DD/YYYY') : 'N/A'}</Text>
                </Col>
                <Col xs={{ flex: '100%' }}
                  sm={{ flex: '50%' }}
                  md={{ flex: '40%' }}
                  lg={{ flex: '25%' }}
                  xl={{ flex: '25%' }}>
                  <Text>Pay From</Text>
                  <br />
                  <Text keyboard>{account.payFromAccount}</Text>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ConfirmPaymentsModal;
