// src/SearchUsers.tsx
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { Input, List, Spin, Button, message } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';

const SEARCH_USERS_QUERY = gql`
  query SearchUsers($limit: Float!, $page: Float!, $search: String) {
    users(limit: $limit, page: $page, search: $search, status: "Active") {
      items {
        id
        firstName
        lastName
      }
      count
    }
  }
`;

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface UsersData {
  users: {
    items: User[];
    count: number;
  };
}

interface UsersVars {
  limit: number;
  page: number;
  search?: string;
}

const Users: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [users, setUsers] = useState<User[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fetchUsers, { loading, data, error }] = useLazyQuery<UsersData, UsersVars>(SEARCH_USERS_QUERY);
  const navigate = useNavigate();

  const loadMore = useCallback(() => {
    if (loading || !hasMore) return;
    fetchUsers({ variables: { limit: 10, page, search } });
  }, [fetchUsers, page, search, hasMore, loading]);

  useEffect(() => {
    if (data) {
      const newUsers = data.users.items;
      setUsers((prevUsers) => (page === 1 ? newUsers : [...prevUsers, ...newUsers]));
      setHasMore(newUsers.length > 0 && users.length + newUsers.length < data.users.count);
    }
    if (error) {
      message.error('Error loading users');
    }
  }, [data, error]);

  useEffect(() => {
    setPage(1);
    setUsers([]);
    setHasMore(true);
  }, [search]);

  useEffect(() => {
    loadMore();
  }, [loadMore, page, search]);

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 300);

  const fetchMoreData = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleUserClick = (userId: string) => {
    localStorage.setItem('userId', userId);
    message.success(`Impersonating user ${userId}`);
    navigate('/bills');
  };

  return (
    <>
      <h1>Users</h1>
      <Input
        placeholder="Search users"
        onChange={handleSearch}
        style={{ marginBottom: 20, width: 200 }}
        prefix={<SearchOutlined />}
      />
      <div
        id="scrollableDiv"
        style={{
          height: 500,
          overflow: 'auto',
          background: '#ffffff',
          borderRadius: 8,
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          padding: '0 16px',
          border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        <InfiniteScroll
          dataLength={users.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<Spin />}
          scrollableTarget="scrollableDiv"
          endMessage={<p style={{ textAlign: 'center' }}>No more users</p>}
          style={{ overflow: 'hidden' }}
        >
          <List
            dataSource={users}
            renderItem={(user) => (
              <List.Item key={user.id}>
                <List.Item.Meta
                  avatar={<UserOutlined />}
                  title={user.firstName + ' ' + user.lastName}
                  style={{ padding: '15px 0px 15px 0px' }}
                />
                <Button type="primary" onClick={() => handleUserClick(user.id)}>
                  Impersonate
                </Button>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Users;
