import React from 'react';
import './Settings.css';

const Settings: React.FC = () => {
  return (
    <>
      <h1>Settings</h1>
    </>
  );
};

export default Settings;
