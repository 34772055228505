import React, { useEffect } from 'react';
import { Modal, Button, Form, Input } from 'antd';
interface AddAccountModalProps {
  visible: boolean;
  onClose: () => void;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};


const AddAccountModal: React.FC<AddAccountModalProps> = ({ visible, onClose }) => {

  useEffect(() => {
    if (!visible) {
      onClose();
    }
  }, [visible, onClose]);

  return (
    <Modal
      title='Add Account'
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      Account Information
      <Form {...formItemLayout} variant="filled" style={{ maxWidth: 800 }}>
        <Form.Item label="Account Name" name="Account Name" rules={[{ required: true, message: 'Please input!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Account Nickname" name="Account Nickname" rules={[{ required: true, message: 'Please input!' }]}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAccountModal;
