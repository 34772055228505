// src/components/ScheduledPayments.tsx
import React from 'react';
import { useQuery } from '@apollo/client';
import { List, Spin, Typography } from 'antd';
import { gql } from '@apollo/client';
import './ScheduledPayments.css';

const { Text } = Typography;

export const GET_USER_SCHEDULED_PAYMENTS = gql`
  query GetUserScheduledPayments($userId: Float!) {
    user(id: $userId) {
      scheduledPayments {
        items {
          id
          amount
          payByDate
          account {
            name
          }
          bankAccount {
            accountNickName
            accountNumber
          }
        }
        count
      }
    }
  }
`;


interface ScheduledPayment {
    id: string;
    amount: number;
    payByDate: string;
    account: {
        name: string;
    };
    bankAccount: {
        accountNickName: string;
        accountNumber: string;
    }
}

interface UserScheduledPaymentsData {
    user: {
        scheduledPayments: {
            items: ScheduledPayment[];
            count: number;
        };
    };
}

interface UserScheduledPaymentsVars {
    userId: number;
}

const maskAccountNumber = (accountNumber: string) => {
    return `...${accountNumber?.slice(-4)}`;
};

const ScheduledPayments: React.FC = () => {
    const { loading, error, data } = useQuery<UserScheduledPaymentsData, UserScheduledPaymentsVars>(
        GET_USER_SCHEDULED_PAYMENTS,
        {
            variables: { userId: Number(localStorage.getItem('userId')) },
        }
    );

    if (loading) return <Spin />;
    if (error) return <p>Error loading scheduled payments.</p>;

    const scheduledPayments = data?.user.scheduledPayments.items || [];

    return (
        <div className="scheduled-payments-container">
            <div className="scrollable-list">
                <List
                    dataSource={scheduledPayments}
                    renderItem={(payment) => (
                        <List.Item key={payment.id}>
                            <List.Item.Meta
                                title={`${payment.account.name}`}
                                style={{ padding: '15px 0px 15px 0px' }}
                                description={
                                    <div>
                                        <Text>{`${payment?.bankAccount?.accountNickName} ${maskAccountNumber(payment?.bankAccount?.accountNumber)}`}</Text>
                                        <br />
                                        <Text>{`Scheduled for ${new Date(payment?.payByDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: '2-digit' })}`}</Text>
                                    </div>
                                }
                            />
                            <div style={{ paddingRight: 15 }}> {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }).format(payment?.amount)}</div>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default ScheduledPayments;
