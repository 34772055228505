import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, Spin, Button, Tabs, TabsProps, Row, Col, Flex } from 'antd';
import { gql } from '@apollo/client';

const GET_ACCOUNT = gql`
  query GetAccount($accountId: Float!) {
    account(id: $accountId) {
      id
      name
      accountNumber
      address {
        addressLine1
        addressLine2
        city
        state
        zip
      }
    }
  }
`;

interface AccountDetailsModalProps {
  accountId: number | null;
  visible: boolean;
  onClose: () => void;
}

const AccountDetailsModal: React.FC<AccountDetailsModalProps> = ({ accountId, visible, onClose }) => {
  const { loading, error, data } = useQuery(GET_ACCOUNT, {
    variables: { accountId },
    skip: !accountId,
  });

  useEffect(() => {
    if (!visible) {
      onClose();
    }
  }, [visible, onClose]);

  const account = data?.account;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Payee Information',
      children: <>
        <p><strong>Account Name:</strong> {account?.name}</p>
        <p><strong>Account Number:</strong> {account?.accountNumber}</p>
        <p><strong>Address Line 1</strong> {account?.address?.addressLine1}</p>
        <p><strong>Address Line 2</strong> {account?.address?.addressLine2}</p>
        <p><strong>City</strong> {account?.address?.city}</p>
        <p><strong>State</strong> {account?.address?.state}</p>
        <p><strong>Zip Code</strong> {account?.address?.zip}</p>
      </>,
    },
    {
      key: '2',
      label: 'Autopay',
      children: <></>
    },
    {
      key: '3',
      label: 'Documents',
      children: <></>
    },
    {
      key: '4',
      label: 'Activity',
      children: <></>
    },
  ];

  return (
    <Modal
      title={account?.name}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      {loading ? (
        <Spin />
      ) : error ? (
        <p>Error loading account details.</p>
      ) : (
        account && (
          <>
            <Tabs defaultActiveKey="1" items={items} />
          </>
        )
      )}
    </Modal>
  );
};

export default AccountDetailsModal;
