import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ScheduledPayments from './ScheduledPayments';
import PaymentHistory from './PaymentHistory';

const items: TabsProps['items'] = [
  {
    key: '2',
    label: 'Scheduled',
    children: <ScheduledPayments />
  },
  {
    key: '3',
    label: 'History',
    children: <PaymentHistory />,
  },
];

const Bills: React.FC = () => {
  return (
    <>
      <h1>Bills</h1>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};

export default Bills;