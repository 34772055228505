import React from 'react';
import './Documents.css';

const Documents: React.FC = () => {
  return (
    <>
      <h1>Documents</h1>
    </>
  );
};

export default Documents;
