import { Outlet } from "react-router-dom";
import './ApprovalsLayout.css';
import { Layout } from 'antd';
import logo from '../../public/images/logo.svg';

const ApprovalsLayout = () => {
    return (
        <Layout>
            <div className='wrapper'>
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <Outlet />
            </div>
        </Layout>
    )
};

export default ApprovalsLayout;